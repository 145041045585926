<template>
    <ModalBox
        :is-active.sync="_isPasswordModalActive"
        :class="{ bg: hasForcedPasswordChange }"
        @after-enter="afterEnter"
        @closed="SET_EMPTY"
        @submit="updatePassword">
        <template #header>
            {{ $t(`admin.profile.modal.password.header.title`) }}
        </template>
        <div class="columns">
            <div class="column">
                <b-field :label="$t('admin.profile.modal.password.body.labels.oldPassword')">
                    <LInput
                        v-model="oldPassword"
                        :placeholder="$t('admin.profile.modal.password.body.labels.oldPassword')"
                        type="password"
                        expanded
                        password-reveal>
                    </LInput>
                </b-field>
            </div>
            <div class="column">
                <EditUserPasswordField
                    v-model="newPassword"
                    :label="$t('admin.profile.modal.password.body.labels.newPassword')"
                    :placeholder="$t('admin.profile.modal.password.body.labels.newPassword')"
                    :message="$t('common.validation.password')">
                </EditUserPasswordField>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            :loading="isPasswordUpdateLoading"
                            class="is-fullwidth is-info is-light"
                            native-type="submit"
                            expanded>
                            {{ $t('common.buttons.save') }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth is-light"
                            expanded
                            @click="cancel">
                            {{ $t(`common.buttons.cancel`) }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { CHANGE_USER_PASSWORD, GET_USER_PERMISSIONS, GET_WHO_AM_I } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_MODAL, UPDATE_PASSWORD_FORM } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import { usePermissions } from "@/stores/common/auth/permissionsStore";

  export default {
    name: "AdminProfilePasswordModal",
    
    components: {
      ModalBox,
      EditUserPasswordField
    },

    setup () {
      return {
        permissionsStore: usePermissions()
      };
    },
    
    computed: {
      ...mapState({
        showPasswordChangeNotification: ({ auth }) => auth.showPasswordChangeNotification
      }),

      ...mapFields("admin/profile/password", {
        fields: ["isPasswordModalActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),
      
      ...mapFields("password", {
        fields: ["oldPassword", "newPassword"],
        base: "form",
        action: UPDATE_PASSWORD_FORM
      }),

      ...mapGetters(["hasForcedPasswordChange"]),

      _isPasswordModalActive: {
        get () {
          return this.isPasswordModalActive;
        },

        set (value) {
          if (!this.hasForcedPasswordChange) {
            this.isPasswordModalActive = value;
          }
        }
      },

      isPasswordUpdateLoading () {
        return this.$wait(`password/${ CHANGE_USER_PASSWORD }`) || this.$wait(GET_WHO_AM_I);
      }
    },
    
    methods: {
      ...mapMutations("admin/profile/password", {
        emptyModalState: SET_EMPTY
      }),
      
      ...mapMutations("password", {
        emptyPasswordState: SET_EMPTY
      }),
      
      ...mapActions("password", [
        CHANGE_USER_PASSWORD
      ]),

      ...mapActions({ GET_WHO_AM_I }),

      ...mapMutations({ GET_USER_PERMISSIONS }),

      [SET_EMPTY] () {
        this.emptyModalState();
        this.emptyPasswordState();
      },

      afterEnter () {
        if (this.hasForcedPasswordChange) {
          this.GET_USER_PERMISSIONS(null);
          this.permissionsStore.$reset();
        }
      },

      cancel () {
        if (!this.hasForcedPasswordChange) {
          this.SET_EMPTY();
        }
      },

      async updatePassword () {
        try {
          const { oldPassword, newPassword } = this;

          if (this.validatePassword(oldPassword, newPassword)) {
            await this.CHANGE_USER_PASSWORD();

            if (this.showPasswordChangeNotification) {
              await this.GET_WHO_AM_I();
              await this.permissionsStore.GET_USER_PERMISSIONS();
            }

            this.SET_EMPTY();
            this.$buefy.toast.open({ message: this.$t("admin.profile.modal.password.messages.success"), type: "is-success" });
          }
        } catch (e) {
          let translation = "admin.profile.modal.password.messages.danger";
          switch (e.status) {
            case 400:
              translation = "common.profile.password.messages.incorrect";
              break;
          }
          
          this.$buefy.toast.open({ message: this.$t(translation), type: "is-danger" });
        }
      },

      validatePassword (oldPassword, newPassword) {
        let error = null;

        if (!oldPassword) {
          error = this.$t("admin.profile.modal.password.validations.old");
        } else if (!newPassword) {
          error = this.$t("admin.profile.modal.password.validations.new");
        } else if (newPassword === oldPassword) {
          error = this.$t("admin.profile.modal.password.validations.equality");
        } else if (`${ newPassword }`.length < 6 || `${ oldPassword }`.length < 6) {
          error = this.$t("admin.users.common.modal.password.messages.minLength");
        }

        if (error) {
          this.$buefy.toast.open({ message: error, type: "is-danger" });
          return false;
        } else return true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bg {
    background-image: url('~@/assets/BG.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
